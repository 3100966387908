import React, { useEffect } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import './Header.css'; // We'll create this CSS file for styling
import CompanyLogo from '../assets/icons/company-logo.png';


function Header() {
    useEffect(() => {
        const waveLinks = document.querySelectorAll('.wave-link');
    
        waveLinks.forEach(link => {
          const letters = link.textContent.split('');
          link.textContent = ''; // Clear the text content
          letters.forEach((letter, index) => {
            const span = document.createElement('span');
            span.textContent = letter;
            span.style.animationDelay = `${index * 0.1}s`;
            link.appendChild(span);
          });
        });
    }, []);

    return (
        <header className="header">
            <div className="container">
                <nav className="nav-left">
                    <ul>
                    <div className="item1"><li><a href="/" className="wave-link">Home</a></li></div>
                        <div className="item2"><li><ScrollLink to="services-section" smooth={true} duration={500} className="wave-link">Services</ScrollLink></li></div>
                    </ul>
                </nav>

                <div className="logo">
                    <img src={CompanyLogo} alt="Company Logo" className="company-logo" />
                </div>

                <nav className="nav-right">
                    <ul>
                        <div className="item3"><li><ScrollLink to="about-section" smooth={true} duration={500} className="wave-link">About</ScrollLink></li></div>
                        <div className="item4"><li><ScrollLink to="contact-section" smooth={true} duration={500} className="wave-link">Contact</ScrollLink></li></div>
                    </ul>
                </nav>
            </div>
        </header>
    );
}

export default Header;
     