import React from 'react';
import './Services.css';
import s1 from '../assets/icons/s1.png';
import s2 from '../assets/icons/s2.png';
import s3 from '../assets/icons/s3.png';
import s4 from '../assets/icons/s4.png';
import s5 from '../assets/icons/s5.png';

function Services() {
  const services = [
    {
      id: 1,
      icon: s1,
      title: 'Individual Tax Services',
      description: 'All personal income earned through employment, sole trader income tax returns, and rental income and expenditure management.',
    },
    {
      id: 2,
      icon: s2,
      title: 'Company Tax Services',
      description: 'Comprehensive financial statements, income reporting, tax offsets and credits, and lodgment details.',
    },
    {
      id: 3,
      icon: s3,
      title: 'Company Formation',
      description: 'Guidance through registration, ABN, TFN, and GST registration, and setting up financial and accounting systems.',
    },
    {
      id: 4,
      icon: s4,
      title: 'Bookkeeping & Payroll',
      description: 'Daily financial record management, accounts payable and receivable, employee payments, and superannuation.',
    },
    {
      id: 5,
      icon: s5,
      title: 'ABN/GST/TFN Registration',
      description: 'Streamlined process for applying and registering your business with necessary tax and regulatory requirements.',
    },
  ];

  return (
    <section className="services-section">
      <h2 className="services-title">Our Services</h2>
      
      {/* First Grid for the first four services */}
      <div className="services-grid">
        {services.slice(0, 4).map((service) => (
          <div key={service.id} className="service-card">
            <div className="icon">
              <img src={service.icon} alt={service.title} />
            </div>
            <div className="title">{service.title}</div>
            <div className="description">{service.description}</div>
          </div>
        ))}
      </div>
      
      {/* Second Grid for the fifth service */}
      <div className="centered-service-grid">
        <div className="service-card">
          <div className="icon">
            <img src={services[4].icon} alt={services[4].title} />
          </div>
          <div className="title">{services[4].title}</div>
          <div className="description">{services[4].description}</div>
        </div>
      </div>
    </section>
  );
}

export default Services;
