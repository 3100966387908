import React from 'react';
import './Footer.css';
import facebookIcon from '../assets/icons/facebook.png';
import instagramIcon from '../assets/icons/instagram.png';
import youtubeIcon from '../assets/icons/youtube.png';
import twitterIcon from '../assets/icons/twitter.png';
import linkedinIcon from '../assets/icons/linkedin.png';
import ipaIcon from '../assets/icons/IPA.png';
import companyLogo from '../assets/icons/company-logo2.png';
import tpbIcon from '../assets/icons/TPB.png';

function Footer() {
  return (
    <footer className="footer-section">
      <div className="footer-social">
        <hr className="footer-divider" />
        <div className="social-icons">
          <a href="https://www.facebook.com/share/M275dc8eEu6x7nba/?mibextid=qi2Omg"
          aria-label="Facebook">
            <img src={facebookIcon} alt="Facebook" />
          </a>
          <a href="https://www.instagram.com/matax_3029?igsh=MWFyOGxxbDM2Zmt5YQ=="
          aria-label="Instagram">
            <img src={instagramIcon} alt="Instagram" />
          </a>
          <a href="https://youtube.com" aria-label="YouTube">
            <img src={youtubeIcon} alt="YouTube" />
          </a>
          <a href="https://twitter.com" aria-label="Twitter">
            <img src={twitterIcon} alt="Twitter" />
          </a>
          <a href="https://linkedin.com" aria-label="LinkedIn">
            <img src={linkedinIcon} alt="LinkedIn" />
          </a>
        </div>
        <hr className="footer-divider" />
      </div>

      <div className="footer-content">
        <div className="footer-left">
          <img src={ipaIcon} alt="Accreditation 1" className="accreditation-1" />
        </div>
        <div className="footer-center">
          <img src={companyLogo} alt="Company Logo" className="company-logo" />
          <p>© 2024 Mentor Accounting & Tax Services, Inc.</p>
        </div>
        <div className="footer-right">
          <img src={tpbIcon} alt="Accreditation 2" className="accreditation-2" />
        </div>
      </div>

      <div className="footer-links">
        <a href="/legal-stuff">Legal Stuff</a>
        <a href="/privacy-policy">Privacy Policy</a>
        <a href="/security">Security</a>
        <a href="/website-accessibility">Website Accessibility</a>
        <a href="/manage-cookies">Manage Cookies</a>
      </div>
    </footer>
  );
}

export default Footer;
