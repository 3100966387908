import React from 'react';
import './Contact.css';
import phoneIcon from '../assets/icons/phone.png';
import emailIcon from '../assets/icons/email.png';
import locationIcon from '../assets/icons/location.png';

function Contact() {
  return (
    <section className="contact-section">
      <div className="contact-left">
        <h2 className="contact-title">Get In Touch</h2>
        <form
          className="contact-form"
          action="https://formspree.io/f/xjkbebqb"
          method="POST"
        >
          <input type="text" name="name" placeholder="Your Name" required />
          <input type="email" name="email" placeholder="Your Email" required />
          <input type="text" name="subject" placeholder="Subject" required />
          <textarea name="message" placeholder="Your Message" rows="5" required></textarea>
          <button type="submit">Send Message</button>
        </form>
        <div className="business-details">
          <div><strong>Business Name:</strong> Mentor Accounting & Tax Services</div>
          <div><strong>ABN:</strong> 40 679 843 157</div>
        </div>
      </div>
      <div className="contact-right">
        <div className="contact-info">
          <div>
            <span className="contact-info-icon"><img src={phoneIcon} alt="Phone" /></span>
            <span className="contact-info-text">+61 425 191786</span>
          </div>
          <div>
            <span className="contact-info-icon"><img src={emailIcon} alt="Email" /></span>
            <span className="contact-info-text">imran@matax.com.au</span>
          </div>
          <div>
            <span className="contact-info-icon"><img src={locationIcon} alt="Location" /></span>
            <span className="contact-info-text">642 Hogans Road, Tarneit Vic 3029</span>
          </div>
        </div>
        <div className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.9276241467976!2d144.6804091!3d-37.855044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad6450e9ef2d129%3A0x77e15f9a59b04429!2s642%20Hogans%20Rd%2C%20Tarneit%20VIC%203029%2C%20Australia!5e0!3m2!1sen!2sus!4v1692269274214!5m2!1sen!2sus"
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
            title="Google Map"
          ></iframe>
        </div>
      </div>
    </section>
  );
}

export default Contact;
