import React from 'react';
import './About.css';
import aboutPhoto from '../assets/icons/about-photo.png';

function About() {
  return (
    <section className="about-section">
      <div className="about-left">
        <h2 className="about-title">About Mentor Accounting & Tax Services</h2>
        <p className="about-text">
          Mentor Accounting & Tax Services is a trusted firm in Australia, offering expert tax and accounting services. Our skilled team of tax accountants is committed to delivering tailored and efficient tax solutions for both individuals and businesses.
        </p>
        <p className="about-text">
          Welcome to Mentor Accounting & Tax Services, your reliable partner in tax and accounting. Our team of seasoned professionals is dedicated to providing tailored solutions to meet your specific financial needs. With extensive experience and expertise, we offer a full range of services, including tax preparation, bookkeeping, and payroll management.
        </p>
        <div className="cards-container">
          <div className="card">
            <div className="card-icon">🌟</div>
            <div>
              <div className="card-title">Our Vision</div>
              <div className="card-text">To be the leading tax and accounting firm, recognized for delivering exceptional value and results-oriented solutions that empower our clients to achieve their financial goals.</div>
            </div>
          </div>
          <div className="card">
            <div className="card-icon">🎯</div>
            <div>
              <div className="card-title">Our Mission</div>
              <div className="card-text">To offer personalized tax accounting services to individuals and businesses of all sizes. We are dedicated to delivering innovative solutions, utilizing our expertise and industry insights to help our clients reach their financial objectives.</div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-right">
        <img src={aboutPhoto} alt="Our Team" />
      </div>
    </section>
  );
}

export default About;
