import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import './Hero.css';
import companyLogoWhite from '../assets/icons/company-logo-white.svg';

function Hero() {
  return (
    <section className="hero-section">
      <div className="hero-content">
        <h1>Simplify Your Taxes with Mentor Accounting & Tax Services</h1>
        <p>Trusted tax solutions for individuals and businesses.</p>
        <ScrollLink to="contact-section" smooth={true} duration={500} className="cta-button">
          Get Started
        </ScrollLink>
      </div>
    </section>
  );
}

export default Hero;
