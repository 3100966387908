import React from 'react';
import Hero from './Hero';
import Services from './Services';
import About from './About';
import Contact from './Contact';


function MainContent() {
    return (
        <main>
            <Hero />
            <About />
            <Services />
            <Contact />
            
        </main>
    );
}

export default MainContent;